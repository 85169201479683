<template>
  <div class="addclient">
 <el-form label-width="130px" :model="form" :rules="ruleForm" label-position="right" ref="form">
      <el-form-item label="客户类型：" prop="userType">
        <el-select v-model="form.userType" :disabled="type==10 || type == 20" >
          <el-option label="企业" :value="20"></el-option>
          <el-option label="个人" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业名称:"  prop="userName">
        <el-input v-model="form.userName" @click="deleted(scope.row.productId)" class="public-input" :disabled="type==10" placeholder="输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="姓名:"  prop="nickName">
        <el-input v-model="form.nickName" class="public-input" placeholder="输入姓名" :disabled="type==10" ></el-input>
      </el-form-item>
      <el-form-item label="联系电话:" prop="userPhone">
        <el-input v-model="form.userPhone" class="public-input" placeholder="输入联系电话" :maxlength="11" type="number" :disabled="type!=0" ></el-input>
      </el-form-item>
      <!-- <el-form-item label="联系地址:" v-if="form.userType == 20" prop="userAddress">
        <el-input v-model="form.userAddress" placeholder="输入联系地址" :disabled="type==10" ></el-input>
      </el-form-item> -->
      <el-form-item label="联系地址:" prop="userAddress">
        <el-input v-model="form.userAddress" class="public-input" placeholder="输入联系地址" :disabled="type==10" ></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码:" prop="creditCode" v-if="form.userType == 20">
        <el-input v-model="form.creditCode" class="public-input" placeholder="输入统一社会引用代码" :disabled="type==10" ></el-input>
      </el-form-item>
      <el-form-item label="注册时间:" v-if="form.createAt">
        <span>{{form.createAt}}</span>
      </el-form-item>
      <el-form-item label="营业执照:" prop="cloudBusiness" v-if="form.userType == 20">
        <el-upload :disabled="type==10" :action="appUrl+'/admin/upload/upload'" accept="image/*" :auto-upload="false"  :show-file-list="false" :on-change="(file,filelist)=>upChange(file,filelist,'license')">
          <img :src="license" class="image" v-if="license" alt="" />
          <el-button v-else type="">上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="LOGO:" prop="userLogo" >
        <el-upload action="" :disabled="type==10" accept="image/*" :show-file-list="false" :auto-upload="false" :on-change="(file,filelist)=>upChange(file,filelist,'logo')">
          <img :src="logo" class="image" v-if="logo" alt="" />
          <el-button v-else type="">上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="购买类型:">
        <el-radio :label="10" v-model="form.buyerType" :disabled="type==10"  >云系统</el-radio>
        <el-radio :label="20" v-model="form.buyerType" :disabled="type==10" >私有化系统部署</el-radio>
      </el-form-item>
      <el-form-item label="收款银行类型:" prop="bankType">
        <el-radio :label="10" v-model="form.bankType" :disabled="type==10"  >特约</el-radio>
        <el-radio :label="20" v-model="form.bankType" :disabled="type==10" >自签</el-radio>
      </el-form-item>
      <el-form-item label="身份证号码:" prop="userIdCode" v-if="form.userType == 10">
        <el-input placeholder="输入身份证号码" type="number" class="public-input" v-model="form.userIdCode" :disabled="type==10" ></el-input>
      </el-form-item>
      <el-form-item label="身份证正面照"  prop="idCardJust" v-if="form.userType == 10">
        <el-upload :disabled="type==10"  action="" :auto-upload="false" accept="image/*" :show-file-list="false" :on-change="(file,filelist)=>upChange(file,filelist,'front')">
          <img :src="front" mode="" class="image" v-if="front" />
          <el-button type="" v-else>上传身份证正面照</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="身份证反面照片" prop="idCardBack" v-if="form.userType == 10">
        <el-upload :disabled="type==10"  action="" accept="image/*" :auto-upload="false" :show-file-list="false" :on-change="(file,filelist)=>upChange(file,filelist,'reverse')">
          <img class="image" :src="reverse" v-if="reverse" />
          <el-button type="" v-else>上传身份证反面照</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="负责人" prop="userCharge" >
        <el-input  placeholder="填写负责人" class="public-input" v-model="form.userCharge"></el-input>
      </el-form-item>
         <el-form-item label="审核状态" v-if="type == 10">
       <el-radio   :label="10" v-model="form.userRecycle">通过</el-radio>
        <el-radio   :label="20" v-model="form.userRecycle">拒绝</el-radio>
      </el-form-item>
        <el-form-item label="审核人" prop="userReviewer" v-if="type == 10">
        <el-input  placeholder="填写审核人" class="public-input" v-model="form.userReviewer"></el-input>
      </el-form-item>
        <el-form-item label="审核意见" v-if="type == 10">
        <el-input placeholder="填写审核意见" type="textarea"  :rows="3" style="width:200px" v-model="form.reviewerOpinion"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>
    </div>
</template>
<script>
export default {
  name: 'addClient',
  data() {
    return {
      id: '',
      form: {
        userType: 10,
        creditCode: "", //统一信用代码
        userPhone:'',
        userName:'',
        userLogo:'',
        cloudBusiness:'', //营业执照
        idCardJust:'',
        idCardBack:'',// 身份证反面
        userIdCode:'',
        userCharge:'', //负责人
        userReviewer:'', //审核人
        reviewerOpinion:'', //审核意见
        userStatus:10,
        userRecycle:10, //审核状态
        buyerType:10,//购买类型
        createAt:'',
        nickName:'',
        userAddress:'',
        bankType: ''
      },
      ruleForm:{
         userType:[
          {required:true,message:'请选择类型',trigger:'blur'}
         ],
          creditCode:[
          {required:true,message:'请输入统一引用代码',trigger:'blur'}
         ],
         userPhone:[
          {required:true,message:'请填写手机号',trigger:'blur'}
         ],
         userName:[
          {required:true,message:'请输入企业名称',trigger:'blur'}
         ],
          userLogo:[
          {required:true,message:'请上传logo'}
         ],
          usercloudBusinessType:[
          {required:true,message:'请上传执照',trigger:'blur'}
         ],
          idCardJust:[
          {required:true,message:'请上传身份证正面',trigger:'blur'}
         ],
          idCardBack:[
          {required:true,message:'请上传身份证反面',trigger:'blur'}
         ],
         userIdCode:[
          {required:true,message:'请输入身份证号码',trigger:'blur'}
         ],
          userCharge:[
          {required:true,message:'请输入负责人',trigger:'blur'}
         ],
         userReviewer:[
          {required:true,message:'请输入审核人',trigger:'blur'}
         ],
         nickName:[
            {required:true,message:'请输入姓名',trigger:'blur'}
         ],
         bankType: [
           {required:true,message:'请选择收款类型',trigger:'change'}
         ]
        //  userAddress:[
        //     {required:true,message:'请输入联系地址',trigger:'blur'}
        //  ]

      },
      license: "", //执照
      logo: "",
      front:'',//正面照
      reverse:'', //反面照
      type:0,  //0添加10审核,20编辑
      appUrl:''
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.appUrl = process.env.VUE_APP_URL;
    if (this.id !=='undefined') {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$request.HttpGet('/admin/cmsUser/find', { id: this.id }).then(res => {
        if(res.data){
          this.form = res.data;
          this.form.userRecycle = 10;
          this.license = res.data.cloudBusiness;
          this.front = res.data.idCardJust;
          this.reverse = res.data.idCardBack;
          this.logo = res.data.userLogo;
        }
      });
    },
    upChange(file,filelist,type){
      let obj = new FormData();
      obj.append('file',file.raw)
      let url = URL.createObjectURL(file.raw)
      this.$request.HttpPost('/admin/upload/upload',obj)
      .then(res => {
        if(type == 'license'){
          this.form.cloudBusiness = this.appUrl +'/'+ res.data.file.url;
        this.license = this.appUrl +'/'+ res.data.file.url
      } else if(type== 'logo'){
        this.form.userLogo = this.appUrl +'/'+ res.data.file.url
        this.logo = this.appUrl +'/'+ res.data.file.url
      } else if(type == 'front'){
          this.form.idCardJust = this.appUrl +'/'+ res.data.file.url
        this.front = this.appUrl +'/'+ res.data.file.url
      } else if(type == 'reverse'){
          this.form.idCardBack = this.appUrl +'/'+ res.data.file.url
        this.reverse = this.appUrl +'/'+ res.data.file.url
      }
      })
    },
    submit(){
      this.$refs.form.validate((valid)=>{
        if(valid){
            if(this.type == '0'){
        this.addClient()
      } else if(this.type == '20'){
        this.editClient(this.type)
      } else if(this.type == '10'){
        this.editClient(this.type)
      }
        }
      })

    },
    addClient(){
       this.$request.HttpPost('/admin/cmsUser/create',this.form)
       .then(res => {
         if(res.code == 0){
          this.$plugin.notifySuccess(res.msg)
           this.$router.back()
         }
         console.log(res)
       })
    },
    editClient(type){
      if(type == 20){
        delete this.form.userRecycle;
        delete  this.form.userReviewer;
        delete this.form.reviewerOpinion
      }
     delete this.form.headerImg;
      this.$request.HttpPost('/admin/cmsUser/update',this.form)
      .then(res => {
       if(res.code == 0){
         console.log(this.$plugin)
           this.$plugin.notifySuccess(res.msg);
           this.$router.back()
         }
      })
    }
  }
};
</script>
<style lang="scss">
.addclient{
  // overflow:auto;
  // height: 100vh;
  padding-bottom: 100px;
}
.addbox{
  // overflow:auto;
  height: 100vh;
  padding-bottom: 50px;
}
.image {
  width: 100px;
  height: 100px;
}
</style>
